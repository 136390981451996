import _omit2 from "lodash/omit";
import _has2 from "lodash/has";
import _get2 from "lodash/get";
import _keyBy2 from "lodash/keyBy";
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { ALL_SWAG_CATEGORY, BOXES_ROUTE, DEFAULT_HIDDEN_PAGINATION_PAGE_SIZE } from 'swag-client-common/constants';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { ALL_SWAG_CATEGORY_LABEL } from 'swag-client-common/constants/category-labels';
import { countSelectedSubcategories, getWholeSubcategoriesForMobile, isSelectedCategory, singleLinks } from 'swag-client-common/utils/mobile-menu.utils';
import { getIsAllProductsPage } from 'swag-client-common/utils/seo.utils';
import { ALL_PRODUCTS_PREFIX, FilterIds } from 'swag-common/constants/main-swag.constants';
import { PRODUCT_SORTING, SUPER_SPEED_PRODUCTION_OPTION } from 'swag-common/constants/product.constants';
import { Price } from 'swag-client-common/utils/price';
import { hasProductSuperSpeedOptionSelector, itemSelector, limitedEditionSelector } from '../../components/page/components/product-card/product-card.selectors';
import { isSearchPageSelector, pathnameSelector } from '../common/common.selectors';
import { isSearchProductsOverlayShownSelector } from '../search-products/search-products.selectors';
import { ALL_SWAG_DESCRIPTION_TEXT, MENU_ITEMS, SUPER_SPEED_CATEGORY_LABEL, SUPER_SPEED_DESCRIPTION_TEXT, SUPER_SPEED_FOR_BOX_DESCRIPTION_TEXT } from './product-listing.constants';
import { facetsCombiner, formatImprintLocationsAmountFilter, formatMinQuantFilter, getCategoryFacetCount, getMaxPrintColorsName, getMinQuantityName, getProductionTimeName, getRangeMaxEntity, getRangeMinEntity, isActiveProdTimeLessThan5DaysFilter, isAllSwagCategory, mapCategoryToFilter } from './product-listing.utils';
import { isFilterOpenTenantSelector } from './selectors/is-filter-open-tenant.selector';
export const productListingReducer = state => state.productListing;
export const selectedFilterSelector = createSelector(productListingReducer, productListing => productListing ? productListing.selectedFilter : {});
export const isSuperSpeedActiveFilterSelector = createSelector(selectedFilterSelector, selectedFilter => selectedFilter.isSuperSpeed);
export const disabledFilterSelector = createSelector(isSuperSpeedActiveFilterSelector, isSuperSpeedActive => {
  if (isSuperSpeedActive) {
    return {
      productionTime: true
    };
  }
  return {};
});
export const categoryByIdsSelector = createSelector(productListingReducer, productListing => _keyBy2(productListing.categories.reduce((result, category) => {
  if (category.subcategories) {
    return result.concat([category, ...category.subcategories]);
  }
  return result.concat(category);
}, []), '_id'));
export const appliedFilterSelector = createSelector(productListingReducer, productListing => productListing.appliedFilter);
export const appliedCategoryFilterSelector = createSelector(appliedFilterSelector, appliedFilter => appliedFilter.categoryId || []);
export const filterLookupsSelector = createSelector(productListingReducer, productListing => productListing.filterLookups);
export const facetsSelector = createSelector(productListingReducer, productListing => productListing.facets);
export const productsAmountSelector = createSelector(facetsSelector, facets => facets.productsAmount);
export const isFilterOpenedSelector = createSelector(productListingReducer, isSearchProductsOverlayShownSelector, isFilterOpenTenantSelector, (productListing, isOverlayShown, isFilterOpenTenant) => {
  return productListing.isFilterOpened && !isOverlayShown && isFilterOpenTenant;
});
export const productsSelector = createSelector(productListingReducer, productListing => productListing.products);
export const pageSelector = createSelector(productListingReducer, productListing => productListing.pagination.page);
export const pageLoadedSelector = createSelector(productListingReducer, productListing => productListing.pagination.pageLoaded);
export const perpageSelector = createSelector(productListingReducer, productListing => productListing.perpage);
export const lastPageSelector = createSelector(productListingReducer, productListing => productListing.pagination.lastPage);
export const isLoadingSelector = createSelector(productListingReducer, productListing => productListing.isLoading);
export const erroredSelector = createSelector(productListingReducer, productListing => productListing.errored);
export const isVisibleSelector = createSelector(productListingReducer, productListing => productListing.isVisible);
export const withAnimationSelector = createSelector(productListingReducer, productListing => productListing.withAnimation);
export const isProductsListFetchedSelector = createSelector(productListingReducer, productListing => !!productListing.products.length);
export const isCategoriesListFetchedSelector = createSelector(productListingReducer, productListing => !!productListing.categories.length);
export const currentCategorySelector = createSelector(categoryByIdsSelector, appliedCategoryFilterSelector, (categoriesByIdsMap, categoryIds) => {
  const defaultValues = {
    slug: ALL_SWAG_CATEGORY,
    _id: ALL_SWAG_CATEGORY
  };
  if (isAllSwagCategory()) {
    return categoriesByIdsMap && categoriesByIdsMap[ALL_SWAG_CATEGORY] || defaultValues;
  }
  let category;
  if (categoryIds.length === 2) {
    category = categoriesByIdsMap[categoryIds[1]];
  } else {
    category = categoriesByIdsMap[categoryIds[0]];
  }
  return category || defaultValues;
});
export const categoriesFAQDataSelector = createSelector(productListingReducer, productListing => productListing.categoriesFAQData);
export const currentCategoryFAQDataSelector = createSelector(currentCategorySelector, categoriesFAQDataSelector, (currentCategory, categoriesFAQData) => {
  return categoriesFAQData.find(({
    catId
  }) => currentCategory._id === catId) || {};
});
export const currentCategoryFAQPromoTextSelector = createSelector(currentCategoryFAQDataSelector, categoryFAQData => categoryFAQData.promoText);
export const currentCategoryFAQQuestionsSelector = createSelector(currentCategoryFAQDataSelector, categoryFAQData => {
  return categoryFAQData.catFAQ || [];
});
export const currentParentCategorySelector = createSelector(categoryByIdsSelector, currentCategorySelector, (categoriesByIdsMap, currentCategory) => {
  if (!currentCategory) {
    return null;
  }
  if (!currentCategory.parent_id) {
    return currentCategory;
  }
  return categoriesByIdsMap[currentCategory.parent_id];
});
export const currentCategorySlugSelector = createSelector(currentCategorySelector, currentCategory => currentCategory.slug);
export const categoriesMapSelector = state => state.productListing.categoriesMap;
export const isAllSwagSelector = createSelector(currentCategorySlugSelector, currentCategoryName => currentCategoryName === ALL_SWAG_CATEGORY);
export const isActiveProdTimeLessThan5DaysFilterSelector = createSelector(selectedFilterSelector, ({
  productionTime
}) => isActiveProdTimeLessThan5DaysFilter(productionTime));
export const isSuperSpeedActiveWithProdTimeLessThan5DaysFilterSelector = createSelector(selectedFilterSelector, ({
  isSuperSpeed,
  productionTime
}) => {
  return isSuperSpeed && (!(productionTime !== null && productionTime !== void 0 && productionTime.length) || isActiveProdTimeLessThan5DaysFilter(productionTime));
});
export const showSuperSpeedIconSelector = createSelector(isSuperSpeedActiveWithProdTimeLessThan5DaysFilterSelector, isActiveProdTimeLessThan5DaysFilterSelector, hasProductSuperSpeedOptionSelector, (isSuperSpeedActiveFilter, isActiveProdTimeLessThan5DaysFilter, hasProductSuperSpeedOption) => (isSuperSpeedActiveFilter || isActiveProdTimeLessThan5DaysFilter) && hasProductSuperSpeedOption);
export const showProductLabelSelector = createSelector(itemSelector, limitedEditionSelector, showSuperSpeedIconSelector, ({
  productLabel
}, {
  isLimitedEdition
}, showSuperSpeedIcon) => {
  return !showSuperSpeedIcon && !isLimitedEdition && productLabel;
});
export const descriptionTitleSelector = createSelector(isAllSwagSelector, currentCategorySelector, isSuperSpeedActiveFilterSelector, isActiveProdTimeLessThan5DaysFilterSelector, (isAllSwag, currentCategory, isSuperSpeedActive, isActiveProdTimeLessThan5Days) => {
  if (isSuperSpeedActive || isActiveProdTimeLessThan5Days) {
    return SUPER_SPEED_CATEGORY_LABEL;
  }
  if (isAllSwag) {
    return ALL_SWAG_CATEGORY_LABEL;
  }
  return currentCategory.descriptionTitle;
});
export const descriptionTextSelector = createSelector(isAllSwagSelector, currentCategorySelector, isSuperSpeedActiveFilterSelector, isActiveProdTimeLessThan5DaysFilterSelector, (isAllSwag, currentCategory, isSuperSpeedActive, isActiveProdTimeLessThan5Days) => {
  if (isSuperSpeedActive) {
    return SUPER_SPEED_FOR_BOX_DESCRIPTION_TEXT;
  }
  if (isActiveProdTimeLessThan5Days) {
    return SUPER_SPEED_DESCRIPTION_TEXT;
  }
  if (isAllSwag) {
    return ALL_SWAG_DESCRIPTION_TEXT;
  }
  return currentCategory.descriptionText;
});
export const categoriesSelector = createSelector(productListingReducer, productListing => productListing.categories.filter(category => category.isVisible));
export const categoriesWithoutAllSwag = createSelector(categoriesSelector, categories => categories.filter(category => category.slug !== ALL_SWAG_CATEGORY));
export const categoriesMap = createSelector(productListingReducer, state => state.categoriesMap);
export const totalSelector = createSelector(productListingReducer, state => state.total);
export const isEmptyProductListSelector = createSelector(totalSelector, total => total < 1);
export const sortingSelector = createSelector(productListingReducer, state => state.sorting);
export const selectedFilterCountSelector = state => {
  const filters = appliedFilterSelector(state);
  return Object.values(filters).reduce((filterCount, nextFilter) => {
    if (Array.isArray(nextFilter)) {
      return filterCount + nextFilter.length;
    }
    return filterCount + 1;
  }, 0);
};
export const currentMainCategorySelector = state => {
  const {
    categories,
    categoriesMap,
    currentCategoryName
  } = state.productListing;
  const currentCategory = categoriesMap[currentCategoryName];
  const parentCategory = currentCategory && currentCategory.parent_id ? categories.find(c => c._id === currentCategory.parent_id) : null;
  return parentCategory || currentCategory;
};
export const productsWithEdgePricesSelector = createSelector(productsSelector, isSuperSpeedActiveWithProdTimeLessThan5DaysFilterSelector, isActiveProdTimeLessThan5DaysFilterSelector, (products, isSuperSpeedActiveWithProdTimeLessThan5DaysFilter, isActiveProdTimeLessThan5DaysFilter) => products.map(prod => addEdgePriceToProduct(prod, isSuperSpeedActiveWithProdTimeLessThan5DaysFilter || isActiveProdTimeLessThan5DaysFilter)));
export const isBoxesPageSelector = createSelector(pathnameSelector, pathname => pathname === BOXES_ROUTE);
export const pageCountSelector = createSelector(totalSelector, total => Math.ceil(total / DEFAULT_HIDDEN_PAGINATION_PAGE_SIZE));
export const subcategoriesSelector = createSelector(categoriesMapSelector, currentCategorySelector, categoriesSelector, (categoriesMap, currentCategory, categories) => {
  const parentCategory = currentCategory && currentCategory.parent_id ? categories.find(c => c._id === currentCategory.parent_id) : null;
  const currentMainCategory = parentCategory || currentCategory;
  return currentMainCategory && currentMainCategory.subcategories && currentMainCategory.subcategories.length > 0 ? currentMainCategory.subcategories : null;
});
export const allSubcategoriesSelector = createSelector(categoriesSelector, categories => {
  return categories.flatMap(category => category.subcategories);
});
export const shouldShowBackLinkSelector = createSelector(currentCategorySlugSelector, categoryName => categoryName !== ALL_SWAG_CATEGORY);
export const categoriesMenuSelector = state => {
  const {
    sorting,
    categoriesMap,
    currentCategoryName,
    appliedFilter,
    isVisible,
    isFilterOpened
  } = state.productListing;
  const currentMainCategory = currentMainCategorySelector(state);
  const currentCategory = categoriesMap[currentCategoryName];
  const isActiveFilterBlockShown = !!Object.keys(appliedFilter).length && isVisible && !(Object.keys(appliedFilter).length === 0 && appliedFilter.categoryId && currentMainCategory.slug !== ALL_SWAG_CATEGORY);
  return {
    categories: categoriesSelector(state),
    sorting,
    categoriesMap,
    currentCategory,
    currentMainCategory,
    currentSubcategoryId: currentCategory.parent_id ? currentCategory._id : null,
    subcategories: currentMainCategory.subcategories.length > 0 ? currentMainCategory.subcategories : null,
    isActiveFilterBlockShown,
    isFilterOpened
  };
};
export const isAllProductsListPageSelector = createSelector(pathnameSelector, pathname => pathname && getIsAllProductsPage(pathname));
export const productListingSelector = createSelector([isCategoriesListFetchedSelector, currentCategorySlugSelector, pageSelector, lastPageSelector, isProductsListFetchedSelector, sortingSelector, selectedFilterCountSelector, appliedFilterSelector, currentMainCategorySelector, isAllProductsListPageSelector, isVisibleSelector, productsWithEdgePricesSelector, isLoadingSelector, shouldShowBackLinkSelector], (isCategoriesListFetched, currentCategoryName, page, lastPage, isProductsListFetched, sorting, filterCount, appliedFilter, currentMainCategory, isAllProductsListPage, isVisible, products, isLoading, shouldShowBackLink) => {
  const isActiveFilterBlockShown = !!Object.keys(appliedFilter).length && isVisible && !(Object.keys(appliedFilter).length === 0 && appliedFilter.categoryId && currentMainCategory.slug !== ALL_SWAG_CATEGORY);
  return {
    isCategoriesListFetched,
    currentCategoryName,
    page,
    lastPage,
    isProductsListFetched,
    sorting,
    filterCount,
    isAllProductsListPage,
    isActiveFilterBlockShown,
    withPurpleWave: !isLoading && !products.length,
    shouldShowBackLink
  };
});
export const sortingOptionsSelector = createSelector(isSearchPageSelector, isSearchPage => {
  if (isSearchPage) {
    return MENU_ITEMS;
  }
  return MENU_ITEMS.filter(menu => menu.name !== PRODUCT_SORTING.RELEVANCE);
});
export const isDescriptionBlockShownSelector = createSelector(isAllSwagSelector, descriptionTitleSelector, descriptionTextSelector, (...args) => args.some(Boolean));
export const isDescriptionBlockOpenSelector = createSelector(isSuperSpeedActiveFilterSelector, isActiveProdTimeLessThan5DaysFilterSelector, (isSuperSpeedActive, isActiveProdTimeLessThan5Days) => {
  return isSuperSpeedActive || isActiveProdTimeLessThan5Days;
});
export const addEdgePriceToProduct = (product, isSuperSpeedEnabled = false) => {
  var _findIndex, _ref, _product$productSetti, _product$productSetti2, _product$productSetti3, _product$productSetti4, _product$productSetti5, _product$productSetti6, _product$productSetti7;
  // @TODO: rename elastic product.embellishmentMethodsData to product.productSettings field
  const productSettingsIndexWithSuperSpeed = (_findIndex = (_ref = (_product$productSetti = product.productSettings) !== null && _product$productSetti !== void 0 ? _product$productSetti : product.embellishmentMethodsData) === null || _ref === void 0 ? void 0 : _ref.findIndex(({
    productionTimeList
  }) => productionTimeList.some(productionTime => productionTime.name === SUPER_SPEED_PRODUCTION_OPTION))) !== null && _findIndex !== void 0 ? _findIndex : -1;
  const prod = isSuperSpeedEnabled && productSettingsIndexWithSuperSpeed !== -1 ? _objectSpread({}, product, {
    minQuant: (_product$productSetti2 = (_product$productSetti3 = product.productSettings) === null || _product$productSetti3 === void 0 ? void 0 : (_product$productSetti4 = _product$productSetti3[productSettingsIndexWithSuperSpeed]) === null || _product$productSetti4 === void 0 ? void 0 : _product$productSetti4.superSpeedSettings.minQuant) !== null && _product$productSetti2 !== void 0 ? _product$productSetti2 : product.minQuant,
    maxPrintColors: (_product$productSetti5 = (_product$productSetti6 = product.productSettings) === null || _product$productSetti6 === void 0 ? void 0 : (_product$productSetti7 = _product$productSetti6[productSettingsIndexWithSuperSpeed]) === null || _product$productSetti7 === void 0 ? void 0 : _product$productSetti7.superSpeedSettings.maxPrintColors) !== null && _product$productSetti5 !== void 0 ? _product$productSetti5 : product.maxPrintColors
  }) : product;
  const edgePrices = Price.calculateDefaultEdgePrice(prod);
  return _objectSpread({}, product, edgePrices);
};
const filterItemPropsSelector = (state, props) => props.filterItem;
const filterDataSelector = createSelector(filterItemPropsSelector, selectedFilterSelector, (filterItem, filterLookups) => {
  const {
    filterData,
    filterId
  } = filterItem;
  return filterLookups[filterId] && filterLookups[filterId].filterData || filterData;
});
const isAllFilterItemsSelectedSelector = createSelector(filterItemPropsSelector, filterDataSelector, selectedFilterSelector, (filterItem, data, selectedFilter) => {
  if (!selectedFilter[filterItem.filterId]) {
    return false;
  }
  return selectedFilter[filterItem.filterId].length >= filterItem.filterData.length;
});
const isAnyFilterAppliedSelector = createSelector(selectedFilterSelector, selectedFilter => {
  const array = Object.keys(selectedFilter).filter(key => key !== FilterIds.categoryId);
  const {
    length
  } = array;
  return length > 0;
});
export const isFullRangeButtonHiddenSelector = createSelector(totalSelector, productsAmountSelector, isAnyFilterAppliedSelector, isAllProductsListPageSelector, (total, productsAmount, isAnyFilterApplied, isAllProductList) => isAllProductList || isAnyFilterApplied || total === productsAmount);
export const filterItemSelector = createSelector(filterLookupsSelector, selectedFilterSelector, filterDataSelector, isAllFilterItemsSelectedSelector, disabledFilterSelector, (filterLookups, selectedFilter, data, isAllFilterItemsSelected, disabledFilter) => ({
  selectedFilter,
  filterLookups,
  disabledFilter,
  data,
  isAllFilterItemsSelected
}));
export const activeFiltersSelector = createSelector(categoriesMapSelector, isFilterOpenedSelector, appliedFilterSelector, totalSelector, currentCategorySelector, currentParentCategorySelector, (categoriesMap, isFilterOpened, appliedFilter, total, currentCategory, parentCategory) => {
  const formattedFilter = _objectSpread({}, appliedFilter);
  delete formattedFilter.includeSearchableOnly;
  if (appliedFilter.categoryId) {
    const appliedFilters = [];
    appliedFilter.categoryId.forEach(category => {
      if (category === ALL_SWAG_CATEGORY) {
        return;
      }
      const foundCategory = Object.values(categoriesMap).find(cat => cat._id === category && (isAllSwagCategory() || cat.parent_id));
      if (foundCategory) {
        appliedFilters.push(foundCategory.name);
      }
    });
    formattedFilter.categoryId = appliedFilters;
    if (!formattedFilter.categoryId.length) {
      delete formattedFilter.categoryId;
    }
  }
  if (appliedFilter.productionTime) {
    formattedFilter.productionTime = [getProductionTimeName(appliedFilter.productionTime[0])];
  }
  if (appliedFilter.minQuantity) {
    formattedFilter.minQuantity = [formatMinQuantFilter(appliedFilter.minQuantity[0])];
  }
  if (appliedFilter.maxPrintColors) {
    formattedFilter.maxPrintColors = [getMaxPrintColorsName(appliedFilter.maxPrintColors[0])];
  }
  if (appliedFilter.imprintLocationsAmount) {
    formattedFilter.imprintLocationsAmount = [formatImprintLocationsAmountFilter(appliedFilter.imprintLocationsAmount[0])];
  }
  if (appliedFilter.price) {
    const [min, max] = appliedFilter.price;
    formattedFilter.price = `from $${min} - $${max}`;
  }
  if (appliedFilter.isBox) {
    formattedFilter.isBox = 'Box compatible';
  }
  if (appliedFilter.isInventory) {
    formattedFilter.isInventory = 'Inventory compatible';
  }
  if (appliedFilter.isSuperSpeed) {
    formattedFilter.isSuperSpeed = 'Super Speed';
  }
  const currentMainCategory = parentCategory || currentCategory;
  const subcategories = currentMainCategory.subcategories && currentMainCategory.subcategories.length > 0 ? currentMainCategory.subcategories : [];
  const categoryFilters = _get2(formattedFilter, 'categoryId', []);
  const shouldShowFiltersPanel = !categoryFilters.includes(currentCategory.name) || Object.keys(formattedFilter).length > 1;
  return {
    total,
    isFilterOpened,
    isSubcategoriesExist: Boolean(subcategories.length),
    shouldShowFiltersPanel,
    filter: formattedFilter,
    isSearch: false,
    columnCount: Math.round(countOfFilterValues(appliedFilter) / 2)
  };
});
export const shouldShowFiltersPanelSelector = createSelector(selectedFilterCountSelector, isFilterOpenedSelector, appliedFilterSelector, currentCategorySelector, (filterCountNum, isFilterOpened, appliedFilter, currentCategory) => {
  const isAppliedFilterIncludeSearchableOnly = _has2(appliedFilter, 'includeSearchableOnly');
  const filterCount = isAppliedFilterIncludeSearchableOnly ? filterCountNum - 1 : filterCountNum;
  const filterKeys = Object.keys(_omit2(appliedFilter, ['includeSearchableOnly']));
  const categoryFilters = _get2(appliedFilter, 'categoryId', []);
  const isCurrentCategoryChosen = categoryFilters.includes(currentCategory._id);
  const shouldShowWhileSideFiltersOpen = isFilterOpened && filterCount > 1;
  const shouldShowInAllSwagCategory = currentCategory._id === ALL_SWAG_CATEGORY && filterCount && !(categoryFilters.length === 1 && isCurrentCategoryChosen);
  const shouldShowIfFewCategoriesChosen = categoryFilters.length > 2 && isCurrentCategoryChosen;
  const shouldShowIfFewFiltersChosen = filterKeys.length > 1 && isCurrentCategoryChosen;
  return shouldShowWhileSideFiltersOpen || shouldShowInAllSwagCategory || shouldShowIfFewCategoriesChosen || shouldShowIfFewFiltersChosen;
});
const countOfFilterValues = filter => {
  let count = 0;
  Object.keys(filter).forEach(key => {
    if (Array.isArray(filter[key])) {
      count += filter[key].length;
    }
    if (typeof filter[key] === 'string' || typeof filter[key] === 'number') {
      count += 1;
    }
  });
  return count;
};
export const getCurrentCategoryNameSelector = createSelector(currentCategorySelector, currentCategory => currentCategory && currentCategory.name);
export const fullProductsListLabelSelector = createSelector(getCurrentCategoryNameSelector, productsAmountSelector, (categoryName, productsAmount) => categoryName && `view our full range of ${categoryName.toLowerCase()} (${productsAmount})`);
export const fullProductsListLinkSelector = createSelector(currentCategorySlugSelector, isAllProductsListPageSelector, (currentCategorySlug, isAllProductsListPage) => {
  if (isAllProductsListPage || !currentCategorySlug) {
    return null;
  }
  return `${APP_ROUTES.COLLECTIONS}/${currentCategorySlug}/${ALL_PRODUCTS_PREFIX}`;
});
export const headerTitleSelector = createSelector(currentParentCategorySelector, (currentCategory = {}) => currentCategory.name);
export const productAttributeFacetsSelector = createSelector(facetsSelector, facets => facets.attributes);
export const colorFamilyFacetsSelector = createSelector(facetsSelector, facets => facets.colorFamily || {});
export const productionTimeFacetsSelector = createSelector(facetsSelector, facets => facets.productionTime || {});
export const minQuantityFacetsSelector = createSelector(facetsSelector, facets => facets.minQuantity || {});
export const embellishmentMethodFacetsSelector = createSelector(facetsSelector, facets => facets.embellishmentMethods || {});
export const boxAndInventoryFacetsSelector = createSelector(facetsSelector, facets => facets.boxAndInventory || {});
export const maxPrintColorsFacetsSelector = createSelector(facetsSelector, facets => facets.maxPrintColorRanges || {});
export const imprintLocationsAmountFacetsSelector = createSelector(facetsSelector, facets => facets.imprintLocationsAmount || {});
export const priceFacetsSelector = createSelector(facetsSelector, facets => facets.price || {});
export const productAttributesSelector = createSelector(productAttributeFacetsSelector, facetsCombiner);
export const colorFamilySelector = createSelector(colorFamilyFacetsSelector, colorFamilyFacets => {
  const filterData = (colorFamilyFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count && item.key !== 'no-family') {
      result.push({
        name: item.key,
        id: item.key,
        count
      });
    }
    return result;
  }, []);
  return {
    filterName: 'Color',
    filterId: FilterIds.colorFamily,
    filterData
  };
});
export const categoryFacetsSelector = createSelector(facetsSelector, facets => facets.allCategories || {});
export const allSwagCategoriesFilterFacetsSelector = createSelector(categoryFacetsSelector, categoryByIdsSelector, (categoryFacets, categoryByIds) => {
  const filterData = Object.values(categoryByIds).reduce((acc, category) => {
    if (!category.subcategories || category.subcategories.length === 0) {
      return acc;
    }
    let itemsCount = 0;
    const children = category.subcategories.reduce((subResult, subCategory) => {
      const subCount = getCategoryFacetCount(categoryFacets, subCategory);
      if (!subCount) {
        return subResult;
      }
      subResult.push(mapCategoryToFilter(subCategory, subCount));
      itemsCount += subCount;
      return subResult;
    }, []);
    const categoryFacetCount = getCategoryFacetCount(categoryFacets, category);
    if (categoryFacetCount) {
      itemsCount += categoryFacetCount;
    }
    if (!itemsCount) {
      return acc;
    }
    category = mapCategoryToFilter(category, itemsCount);
    category.children = children;
    acc.push(category);
    return acc;
  }, []);
  return {
    filterName: 'Category',
    filterId: FilterIds.categoryId,
    filterData
  };
});
export const onlyParentCategoriesFilterFacetsSelector = createSelector(categoryFacetsSelector, categoryByIdsSelector, currentParentCategorySelector, (categoryFacets, categoryByIds, currentCategory) => {
  const filterData = (categoryFacets.global || []).reduce((result, category) => {
    const foundCategory = categoryByIds[category.key];
    if (!foundCategory) {
      return result;
    }
    const isCategoryBelongsToCurrentCategory = foundCategory.parent_id === currentCategory._id;
    if (!isCategoryBelongsToCurrentCategory) {
      return result;
    }
    result.push(mapCategoryToFilter(foundCategory, category.doc_count));
    return result;
  }, []).filter(item => item && item.count).sort((a, b) => a.order - b.order);
  return {
    filterName: 'Category',
    filterId: FilterIds.categoryId,
    filterData
  };
});
export const categoriesFilterFacetsSelector = createSelector(currentCategorySelector, allSwagCategoriesFilterFacetsSelector, onlyParentCategoriesFilterFacetsSelector, (category, allSwagCategoriesFilters, onlyParentCategoriesFilters) => {
  if (category && category.slug === ALL_SWAG_CATEGORY) {
    return allSwagCategoriesFilters;
  }
  return onlyParentCategoriesFilters;
});
export const productionTimeSelector = createSelector(productionTimeFacetsSelector, productionTimeFacets => {
  const filterData = (productionTimeFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count) {
      result.push({
        name: getProductionTimeName(item.key),
        id: item.key,
        count
      });
    }
    return result;
  }, []);
  return {
    filterName: 'Production Time',
    filterId: FilterIds.productionTime,
    filterData
  };
});
export const minQuantitySelector = createSelector(minQuantityFacetsSelector, minQuantityFacets => {
  const filterData = (minQuantityFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count) {
      result.push({
        name: getMinQuantityName(item.key),
        id: item.key,
        count
      });
    }
    return result;
  }, []);
  return {
    filterName: 'Minimum Quantity',
    filterId: FilterIds.minQuantity,
    filterData
  };
});
export const embellishmentMethodSelector = createSelector(embellishmentMethodFacetsSelector, embellishmentMethodFacets => {
  const filterData = (embellishmentMethodFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count) {
      result.push({
        name: item.key,
        id: item.key,
        count
      });
    }
    return result;
  }, []).sort((a, b) => a.name.localeCompare(b.name, 'en', {
    sensitivity: 'base'
  }));
  return {
    filterName: 'Imprint method',
    filterId: FilterIds.embellishmentMethod,
    filterData
  };
});
export const boxAndInventorySelector = createSelector(boxAndInventoryFacetsSelector, boxAndInventoryFacets => {
  const filterData = boxAndInventoryFacets.global || [{
    name: FilterIds.isBox,
    id: FilterIds.isBox,
    value: false
  }, {
    name: FilterIds.isInventory,
    id: FilterIds.isInventory,
    value: false
  }, {
    name: FilterIds.isSuperSpeed,
    id: FilterIds.isSuperSpeed,
    value: false
  }];
  return {
    filterName: 'Box and Inventory',
    filterId: FilterIds.boxAndInventory,
    filterData
  };
});
export const shopCustomColorsSelector = () => ({
  secondaryColor: '',
  primaryColor: ''
});
export const maxPrintColorsSelector = createSelector(maxPrintColorsFacetsSelector, maxPrintColorsFacets => {
  const filterData = (maxPrintColorsFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count) {
      result.push({
        name: getMaxPrintColorsName(item.key),
        id: item.key,
        sortOrder: !item.to ? item.from : 10,
        count
      });
    }
    return result;
  }, []).sort((a, b) => a.sortOrder - b.sortOrder);
  return {
    filterName: 'Design Color Limit',
    filterId: FilterIds.maxPrintColors,
    filterData
  };
});
export const imprintLocationsAmountSelector = createSelector(imprintLocationsAmountFacetsSelector, imprintLocationsAmountFacets => {
  const filterData = (imprintLocationsAmountFacets.global || []).reduce((result, item) => {
    const count = item.doc_count;
    if (count) {
      result.push({
        name: formatImprintLocationsAmountFilter(item.key),
        id: item.key,
        count
      });
    }
    return result;
  }, []).sort(({
    id: a
  }, {
    id: b
  }) => {
    const firstNum = isFinite(+a) ? +a : 10;
    const secondNum = isFinite(+b) ? +b : 10;
    return firstNum - secondNum;
  });
  return {
    filterName: 'Locations to print',
    filterId: FilterIds.imprintLocationsAmount,
    filterData
  };
});
const createPriceFilterData = ({
  min,
  max
}) => {
  return [{
    name: 'min',
    id: 'min',
    data: getRangeMinEntity(min)
  }, {
    name: 'max',
    id: 'max',
    data: getRangeMaxEntity(max)
  }];
};
export const pricingSelector = createSelector(priceFacetsSelector, priceFacets => {
  const INITIAL_FACET = {
    max: 0,
    min: 0
  };
  const DEFAULT_PRICE_SETTINGS = {
    filterName: 'Price range',
    filterId: FilterIds.price
  };
  const {
    global = INITIAL_FACET
  } = priceFacets;
  return _objectSpread({}, DEFAULT_PRICE_SETTINGS, {
    filterData: createPriceFilterData(global)
  });
});
export const selectedCategoryIdsSelector = createSelector(selectedFilterSelector, ({
  categoryId
}) => categoryId ? Object.values(categoryId) : []);

//for mob menu ------------------------------------------------
export const highlightAllSwagSelector = createSelector(categoriesSelector, selectedCategoryIdsSelector, (categories, selectedCategoryIds) => categories.reduce((acc, parentCategory) => {
  if (countSelectedSubcategories(getWholeSubcategoriesForMobile(parentCategory, parentCategory.subcategories), selectedCategoryIds)) {
    return acc + 1;
  } else {
    return acc;
  }
}, 0) > 1);
export const highlightCategorySelector = createSelector((state, parentCategory) => parentCategory, selectedCategoryIdsSelector, highlightAllSwagSelector, (parentCategory, selectedCategoryIds, isHighlightAllSwag) => {
  if (isHighlightAllSwag && parentCategory.slug !== singleLinks[0]) {
    return false;
  }
  return parentCategory.slug === singleLinks[0] && !selectedCategoryIds.length || parentCategory.slug === singleLinks[0] && isHighlightAllSwag ? true : isSelectedCategory(getWholeSubcategoriesForMobile(parentCategory, parentCategory.subcategories), selectedCategoryIds);
});
const isHighlightMainCategory = (subcategory, subcategories, selectedCategoryIds, isHighlightAllSwag) => {
  if (isHighlightAllSwag) {
    return false;
  }
  return selectedCategoryIds.includes(subcategory._id) && !countSelectedSubcategories(subcategories, selectedCategoryIds) || countSelectedSubcategories(subcategories, selectedCategoryIds) > 1;
};
const isHighlightSubCategoryAsSelected = (subcategory, subcategories, selectedCategoryIds, isHighlightAllSwag) => {
  if (isHighlightAllSwag) {
    return false;
  }
  if (countSelectedSubcategories(subcategories, selectedCategoryIds) > 1) {
    return false;
  }
  return isSelectedCategory([subcategory], selectedCategoryIds);
};
export const getUpdatedCategorySelector = createSelector((state, category) => category, selectedCategoryIdsSelector, highlightAllSwagSelector, (category, selectedCategoryIds, isHighlightAllSwag) => _objectSpread({}, category, {
  isHighlightSubCategory: isHighlightMainCategory(category, category.subcategories, selectedCategoryIds, isHighlightAllSwag),
  subcategories: category.subcategories.map(subcategory => _objectSpread({}, subcategory, {
    isHighlightSubCategory: isHighlightSubCategoryAsSelected(subcategory, category.subcategories, selectedCategoryIds, isHighlightAllSwag)
  }))
}));
export const setInitStateMobMenuSelector = createSelector((state, parentCategory) => parentCategory, (state, parentCategory, subcategories) => subcategories, selectedCategoryIdsSelector, highlightAllSwagSelector, (parentCategory, subcategories, selectedCategoryIds, isHighlightAllSwag) => singleLinks.includes(parentCategory.slug) || isHighlightAllSwag ? false : isSelectedCategory(getWholeSubcategoriesForMobile(parentCategory, subcategories), selectedCategoryIds));
//for mob menu ------------------------------------------------

export const categoryLogosForPrerender = createSelector(productListingReducer, ({
  logoCategories
}) => logoCategories.map(item => item.logoUrl));